.app-layout {
  background: #eee;
  min-height: 100vh;
  display: flex;
}

.app-layout header {
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.app-layout header .mobile-toggle-nav {
  background: none;
  border: 0;
  margin-left: 24px;
  display: none;
}

.app-layout header img {
  margin-left: 22px;
  margin-right: 10px;
}

.app-layout header span {
  color: #636184;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
}

.app-layout header button:last-child {
  color: #728c73;
  background: none;
  border: none;
  border-left: 1px solid #d9d9d9;
  height: 60px;
  margin-left: auto;
  padding: 0 42px;
  font-size: 18px;
  font-weight: 500;
}

.app-layout nav, .app-layout .app-content {
  margin-top: 60px;
}

.app-layout nav {
  background: #fff;
  width: 225px;
  min-width: 225px;
  padding-top: 40px;
}

.app-layout nav a {
  color: #555;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.app-layout nav a span {
  margin-left: 8px;
}

.app-layout nav a.active {
  color: #728c73;
  font-weight: bold;
}

.app-layout nav a svg path {
  fill: #555;
}

.app-layout nav a.active svg path {
  fill: #728c73;
}

.app-layout .app-content {
  flex-grow: 1;
  padding: 24px;
}

.app-layout .app-content h1 {
  margin-top: 0;
}

@media only screen and (width <= 992px) {
  .app-layout .app-content {
    padding: 12px;
  }

  .app-layout header {
    justify-content: space-between;
  }

  .app-layout header .mobile-toggle-nav {
    display: block;
  }

  .app-layout header img, .app-layout header span {
    display: none;
  }

  .app-layout nav {
    z-index: 100;
    border-top: 1px solid #d9d9d9;
    height: 100vh;
    transition: left .3s;
    position: fixed;
    left: -225px;
  }

  .app-layout .mobile-nav-open {
    left: 0 !important;
  }
}

@media print {
  .app-layout header, .app-layout nav, button {
    display: none !important;
  }
}

/*# sourceMappingURL=App.css.map */
